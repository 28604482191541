import { useEffect, useState, useContext } from 'react';
import { Neo4jContext } from './neo4jDriver';

function GetBuildings() {
  const [data, setData] = useState([]);
  const driver = useContext(Neo4jContext);

  useEffect(() => {
    const session = driver.session();
    session.run('MATCH (b:Building) RETURN b')
      .then((result) => {
        const data = result.records.map((record) => record.get('b').properties);
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        session.close();
      });
  }, [driver]);
  const options = data.map((item) => ({ value: item.name, label: item.name }));
  return options;
}

export default GetBuildings;
