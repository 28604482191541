//This components is used to send a query to the Neo4j AuraDB every 24 hours, so that it does not gets paused.
import { useEffect, useContext } from "react";
import { Neo4jContext } from './neo4jDriver';

const KeepAlive = () => {
  const driver = useContext(Neo4jContext);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const session = driver.session();
      session.run("MATCH (n) RETURN count(n)")
        .then(result => {
          console.log(`There are ${result.records[0].get(0)} nodes in the database`);
          session.close();
        })
        .catch(error => {
          console.error("Error sending keep alive request", error);
          session.close();
        });
    }, 24 * 60 * 60 * 1000); // Send keep alive request once every 24 hours

    return () => {
      clearInterval(intervalId);
    };
  }, [driver]);

  return null;
};

export default KeepAlive;
