import React from 'react';
import Select from 'react-select';
import { tokens } from '../theme';
import { useTheme } from '@mui/material';

const MultiSelect = ({
    field,
    form,
    options,
    isMulti = false,
    placeholder = 'Select'
    
}) => {
    const currenttheme = useTheme();
const colors = tokens(currenttheme.palette.mode);
    function onChange(option) {
        form.setFieldValue(
            field.name,
            option ? (option).map((item) => item.value) : [],
        );
    }

    const getValue = () => {
        if (options) {
            return isMulti
                ? options.filter((option) => field.value.indexOf(option.value) >= 0)
                : options.find((option) => option.value === field.value);
        } else {
            return isMulti ? [] : ('');
        }
    };

    if (!isMulti) {
        return (
            <Select
                options={options}
                name={field.name}
                value={options ? options.find(option => option.value === field.value) : ''}
                onChange={(option) => form.setFieldValue(field.name, option.value)}
                onBlur={field.onBlur}
                placeholder={placeholder}
                theme={(theme) => ({ //For styling of select component
                    ...theme,
                    colors:{
                    primary: colors.primary[200], //container selected
                    primary25: colors.blueAccent[700], //option hover
                    primary50: colors.greenAccent[500], //option click
                    primary75: colors.redAccent[500], //?
                    danger: colors.redAccent[500], //cross
                    dangerlight: colors.redAccent[300], // ?
                    neutral0: colors.primary[400], //background
                    neutral5: colors.redAccent[500], // ?
                    neutral10: colors.grey[500],//selected option
                    neutral20: colors.grey[300],//border color
                    neutral30: colors.grey[100],//bordercolor hover
                    neutral40: colors.blueAccent[400], // No options text color
                    neutral50: colors.grey[200], // label text color 
                    neutral60: colors.primary[500], // menu options (top right)
                    neutral70: colors.redAccent[500], // ?
                    neutral80: colors.grey[100], // text color (typing)
                    neutral90: colors.redAccent[500], // ?
                    }
    
                    })
                }
            />
        )
    } else {
        return (
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={options}
                isMulti={true}
                placeholder={placeholder}
                closeMenuOnSelect={false}
                theme={(theme) => ({ //For styling of select component
                ...theme,
                colors:{
                primary: colors.primary[200], //container selected
                primary25: colors.blueAccent[700], //option hover
                primary50: colors.greenAccent[500], //option click
                primary75: colors.redAccent[500], //?
                danger: colors.redAccent[500], //cross
                dangerlight: colors.redAccent[300], // ?
                neutral0: colors.primary[400], //background
                neutral5: colors.redAccent[500], // ?
                neutral10: colors.grey[500],//selected option
                neutral20: colors.grey[300],//border color
                neutral30: colors.grey[100],//bordercolor hover
                neutral40: colors.blueAccent[400], // No options text color
                neutral50: colors.grey[200], // label text color 
                neutral60: colors.primary[500], // menu options (top right)
                neutral70: colors.redAccent[500], // ?
                neutral80: colors.grey[100], // text color (typing)
                neutral90: colors.redAccent[500], // ?
                }

                })
            }
             //   styles={customStyles}
            />
        )
    }
}

export default MultiSelect;