import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import React, { useRef, useEffect, useContext, useState } from 'react';
import { Neo4jContext } from '../../components/neo4jDriver';
import QueryForm from "./queryform";
import { useGraphOptions } from './createGraphOptions';
import { NetworkDataContext } from '../../components/NetworkDataContext';

function GraphView(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const bordercolor = colors.primary[400];
  const driver = useContext(Neo4jContext);
  const networkRef = useRef(null);
  const [tooltip, setTooltip] = useState(null);
  const options = useGraphOptions();
  const [query, setQuery] = useState(`MATCH  (b:Building) WHERE b.name = "ttest" RETURN b`);
  const [UpdateType, setUpdateType] = useState("New"); // checks which button is clicked
  const { networkdata, setnetworkData } = useContext(NetworkDataContext);
  useEffect(() => {
    const vis = require('vis-network');
    const container = document.getElementById('graph');
    let session = null;
    const data = { nodes: [], edges: [] };
      if (!networkRef.current) {
        networkRef.current = new vis.Network(container, data, options);
        networkRef.current.on('click', function(properties) {
          if (properties.nodes.length > 0) {
            const nodeId = properties.nodes[0];
            session = driver.session();
            session
              .run(`MATCH (node) WHERE id(node) = ${nodeId} RETURN node`)
              .then((result) => {
                const record = result.records[0];
                const node = record.get('node');
                // console.log('node properties: ' + JSON.stringify(node.properties, null, 4));
                setTooltip(
                  <div>
  <h2>{node.labels[0].replace(/_/g, ' ')}</h2>
  {Object.entries(node.properties).map(([key, value]) => {
    if (key === 'location') {
      return null; // Skip rendering location key and value
    }
    return (
      <p key={key}>
        <b>{key}: </b>
        {value.startsWith("https") || value.startsWith("http") ? (
          <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
        ) : (
          value
        )}
      </p>
    );
  })}
</div>
                );
              
              
            })
            .catch((error) => console.error(error))
            .finally(() => {
              session.close();
            });
        } else {
          setTooltip(null); // clear the tooltip if no node is clicked
          session.close();
        }
      });
    }
    const network = networkRef.current;

    session = driver.session();
    session
    .run(query)
    .then((result) => {
      if (UpdateType === "New"){
        setnetworkData(data);
      }
      const nodes = {};
      result.records.forEach((record) => {
        if (record.keys.includes('b')) { 
        const buildingNode = record.get('b');
        const buildingId = buildingNode.identity.toString();         // get the Neo4j internal id as a string
        if (!nodes[buildingId] && !networkdata.nodes.some(node => node.id === buildingId)) { 
          nodes[buildingId] = { id: buildingId, label: buildingNode.properties.name, color: {background: "#F79767", border: "#f25c11"}};
          data.nodes.push(nodes[buildingId]);
          networkdata.nodes.push(nodes[buildingId]);
        }
      }
      if (record.keys.includes('ds')) { 
        const designNode = record.get('ds');
        const designId = designNode.identity.toString(); // get the Neo4j internal id as a string
        if (designId && !networkdata.nodes.some(node => node.id === designId)) { // add an additional check for strategyId
          nodes[designId] = { id: designId, label: designNode.properties.Strategy, color: {background: "#ffe081", border: "#ffc71d"} };
          data.nodes.push(nodes[designId]);
          networkdata.nodes.push(nodes[designId]);
        }
      }
      if (record.keys.includes('es')) { 
        const ecoNode = record.get('es');
        const ecoId = ecoNode.identity.toString(); // get the Neo4j internal id as a string
        if (ecoId && !networkdata.nodes.some(node => node.id === ecoId)) { // add an additional check for strategyId
          const group = ecoNode.properties.group;
          const color = group === "Regulating Services" ? "#8de354" : group === "Cultural Services" ? "#da4949" : group === "Provisioning Services" ? "#54d2e3": "purple" ;
          const colorb = group === "Regulating Services" ? "#64c821": group === "Cultural Services" ? "#b52525" : group === "Provisioning Services" ? "#21b4c8": "purple";
          // replace the above ternary condition with the logic to map different groups to different colors
          nodes[ecoId] = { id: ecoId, label: ecoNode.properties.categories, color: {background: color, border: colorb} };
          data.nodes.push(nodes[ecoId]);
          networkdata.nodes.push(nodes[ecoId]);
        }
      }
        if (record.keys.includes('i')) {
          const edge = record.get('i');
          const buildingNode = record.get('b');
          const buildingId = buildingNode.identity.toString(); // get the Neo4j internal id as a string // add an additional check for strategyId
          const designNode = record.get('ds');
          const designId = designNode.identity.toString(); // get the Neo4j internal id as a string
          const edgeId = edge.identity.toString();
          if (edgeId && !networkdata.edges.some(edge => edge.id === edgeId)) {
          data.edges.push({
            id: edgeId,
            from: buildingId,
            to: designId,
            label: String(edge.type),
            properties: {},
          });
          networkdata.edges.push({
            id: edgeId,
            from: buildingId,
            to: designId,
            label: String(edge.type),
            properties: {},
          });
      }
    }
      if (record.keys.includes('g')) {
        const edge = record.get('g');
        const ecoNode = record.get('es');
        const ecoId = ecoNode.identity.toString(); // get the Neo4j internal id as a string
        const designNode = record.get('ds');
        const designId = designNode.identity.toString();
        const edgeId = edge.identity.toString();
          if (edgeId && !networkdata.edges.some(edge => edge.id === edgeId)) { // get the Neo4j internal id as a string
        data.edges.push({
          id: edgeId,
          from: designId,
          to: ecoId,
          label: String(edge.type),
          properties: {},
        });
        networkdata.edges.push({
          id: edgeId,
          from: designId,
          to: ecoId,
          label: String(edge.type),
          properties: {},
        });
    }
  }
        });
        if (UpdateType === "New"){
          network.setData(data);
        }
        if (UpdateType === "Extend"){
          network.body.data.nodes.add(data.nodes);
          network.body.data.edges.add(data.edges);
          network.redraw();
          console.log("EXTEND" ,networkdata)
          console.log("dataNODES", data.nodes)
          console.log("dataEdges", data.edges)
          setnetworkData(prevNetworkData => ({
            nodes: [...prevNetworkData.nodes, ...data.nodes],
            edges: [...prevNetworkData.edges, ...data.edges]
          }));
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        session.close();
      });

//eventlistener for extending the graph, either with relationships or from the node selected. 
      document.addEventListener('keydown', (event) => {
          const selectedNodes = network.getSelectedNodes();
          if (selectedNodes.length) {
          const nodeId = selectedNodes[0];
          session = driver.session();
          let extendquery = '';
          if (event.ctrlKey && event.key === ']') {
            extendquery = `
              MATCH (node)-[r]->(related)
              WHERE id(node) = ${nodeId}
              RETURN node, r, related
            `
          } else if (event.ctrlKey && event.key === '[') {
            extendquery =`
              MATCH (node)<-[r]-(related)
              WHERE id(node) = ${nodeId}
              RETURN node, r, related
            `
          }
          session.run(extendquery)
            .then((result) => {
              const data = { nodes: [], edges: [] };
              result.records.forEach((record) => {
                const node = record.get('related');
                const nodeId = node.identity.toString();
                const nodeLabel = node.labels[0];
                const nodeProperties = node.properties;
                if (!networkdata.nodes.some(node => node.id === nodeId)) { 
                if (nodeLabel === "Building"){

                      data.nodes.push({
                        id: nodeId,
                        label: node.properties.name,
                        color: {background: "#F79767", border: "#f25c11"},
                        ...nodeProperties,
                      });
                      networkdata.nodes.push({
                        id: nodeId,
                        label: node.properties.name,
                        color: {background: "#F79767", border: "#f25c11"},
                        ...nodeProperties,
                      });
                }
                if (nodeLabel === "Ecosystem_Service"){
                  const group = node.properties.group;
                const color = group === "Regulating Services" ? "#8de354" : group === "Cultural Services" ? "#da4949" : group === "Provisioning Services" ? "#54d2e3": "purple" ;
                const colorb = group === "Regulating Services" ? "#64c821": group === "Cultural Services" ? "#b52525" : group === "Provisioning Services" ? "#21b4c8": "purple";

                    data.nodes.push({
                      id: nodeId,
                      label: node.properties.categories,
                      color: {background: color, border: colorb},
                      ...nodeProperties,
                    });
                    networkdata.nodes.push({
                      id: nodeId,
                      label: node.properties.categories,
                      color: {background: color, border: colorb},
                      ...nodeProperties,
                    });

                  }
                  if (nodeLabel === "Design_Strategies"){

                      data.nodes.push({
                        id: nodeId,
                        label: node.properties.Strategy,
                        color: {background: "#ffe081", border: "#ffe081"},
                        ...nodeProperties,
                      });
                      networkdata.nodes.push({
                        id: nodeId,
                        label: node.properties.Strategies,
                        color: {background: "#ffe081", border: "#ffe081"},
                        ...nodeProperties,
                      });
                    }
                }
                const edge = record.get('r');
                const edgeId = edge.identity.toString();
                const fromId = edge.start.toString();
                const toId = edge.end.toString();
                const edgeLabel = edge.type;
                const edgeProperties = edge.properties;
                if (!networkdata.edges.some(edge => edge.id === edgeId)) { 
                  data.edges.push({
                    id: edgeId,
                    from: fromId,
                    to: toId,
                    label: edgeLabel,
                    ...edgeProperties,
                  });
                  networkdata.edges.push({
                    id: edgeId,
                    from: fromId,
                    to: toId,
                    label: edgeLabel,
                    ...edgeProperties,
                  });
                }
              });
                network.body.data.nodes.add(data.nodes);
                network.body.data.edges.add(data.edges);
                network.redraw();
                setnetworkData(prevNetworkData => ({
                  nodes: [...prevNetworkData.nodes, ...data.nodes],
                  edges: [...prevNetworkData.edges, ...data.edges]
                }));
            })
            .catch((error) => console.error(error))
            .finally(() => {
              session.close();
            });
        }
      });
    }, [query, UpdateType]);




    const handleSubmit = (values, buttonType) => {
    setUpdateType(buttonType)
      const select1 = values.select1;
 // for new visualization
        if (values.property === ""){ //No property is selected
              if (select1.split(":")[0] !== "i" && select1.split(":")[0] !== "g") {
                const query = `MATCH (${select1}) RETURN ${select1.split(":")[0]}`; 
                console.log(query);
                setQuery(query);
              }
            if (select1.split(":")[0] === "i"){
                const query = `MATCH (b:Building)-[${select1}]-(ds:Design_Strategies) RETURN b, i, ds`; 
                console.log(query);
                setQuery(query);
              }
              if (select1.split(":")[0] === "g"){
                const query = `MATCH (es:Ecosystem_Service)-[${select1}]-(ds:Design_Strategies) RETURN es, g, ds`; 
                console.log(query);
                setQuery(query);
              }
        }
        if (values.property !== ""){ //Property is selected
              if (select1.split(":")[0] !== "i" && select1.split(":")[0] !== "g") {
                const query = `MATCH (${select1}) WHERE ${select1.split(":")[0]}.${values.property} CONTAINS "${values.propertyValue}" RETURN ${select1.split(":")[0]}`; 
                console.log(query);
                setQuery(query);
              }
              if (select1.split(":")[0] === "i"){
                const query = `MATCH (b:Building)-[${select1}]-(ds:Design_Strategies) RETURN b, i, ds`; 
                console.log(query);
                setQuery(query);
              }
              if (select1.split(":")[0] === "g"){
                const query = `MATCH (es:Ecosystem_Service)-[${select1}]-(ds:Design_Strategies) RETURN es, g, ds`; 
                console.log(query);
                setQuery(query);
              }
    }
  }


  return (
    <Box>
      <QueryForm onSubmit={handleSubmit} />
    <Box style={{ position: 'relative' }}>
      <Box id="graph" style={{ paddingLeft: "2px", width: '100%', height: '85vh', borderRightColor: bordercolor, borderRightStyle: "solid", borderRightWidth: "10px", borderTopColor: bordercolor, borderTopStyle: "solid", borderTopWidth: "10px", }}></Box>
      {tooltip && (
        <Box
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 100,
            border: `5px solid ${bordercolor}`,
            padding: '10px',
            wordWrap: 'break-word',
            overflowX: 'auto', // set the overflow-x property to auto
            minWidth: '250px', // set the minimum width to 200 pixels
            maxWidth: '250px', // set the maximum width to 400 pixels
            backgroundColor: theme.palette.background.default,
          }}
        >
          {tooltip}
        </Box>
      )}
    </Box>
    </Box>
  );
}


export default GraphView;
