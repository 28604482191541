import { Box, Button, TextField, FormHelperText} from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import MultiSelect from '../../components/CustomSelect.jsx';
import { useContext } from 'react';
import { Neo4jContext } from '../../components/neo4jDriver';
import { useNavigate } from 'react-router-dom';
import GetStrategies from '../../components/getStrategies.jsx';
import axios from 'axios';
import GetBuildings from '../../components/getBuildings.jsx';
const FormBuilding = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const driver = useContext(Neo4jContext);
  const navigate = useNavigate();
  const options = GetStrategies();
  const optionsDelete = GetBuildings();
  const session = driver.session();
  const tx = session.beginTransaction();
  //Submit form
  const handleFormSubmit = async (values) => {
    console.log("values",values);
    console.log("country",values.country);
    const fullAddress = [values.country, values.city, values.street].filter(Boolean).join(', '); // filter out empty strings and join with ', '
    console.log("address",fullAddress)
    const fetchCoordinates = async (address) => {
      console.log("START FETCH COORDINATES")
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`);
        if (response.data.length) {
          const { lat, lon } = response.data[0];
          console.log("Log every item in BuildingData", address, { lat, lon })
          console.log("lat lon created")
          return { lat, lon}
        } else {
          throw new Error('Could not find address');
        }
      } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch coordinates');
      }
    };

    const {lat, lon} = await fetchCoordinates(fullAddress)
    console.log("LATT", lat)
    console.log(lon)

    

    try {   
    //Create Building
    tx.run(`CREATE (:Building {name: $buildingName, country: $country, city: $city, street: $street, link: $link1, additional_link: $link2, photo: $linkphoto, location: point({ latitude: $lat, longitude: $lon })});`,
    {buildingName: values.buildingName, country: values.country, city: values.city, street: values.street, link1: values.link1, link2: values.link2, linkphoto: values.linkphoto, lat: parseFloat(lat), lon: parseFloat(lon)})
    console.log("node created")
    //Make Relationship
      console.log('Design strategies:', values.designStrategies);
      for (const designStrategy of values.designStrategies) {
        console.log(`Creating relationship for ${values.buildingName} and ${designStrategy}`);  
        await tx.run('MATCH(b:Building), (ds:Design_Strategies) WHERE b.name = $buildingName AND ds.Strategy = $designStrategy CREATE (b)-[:Implemented]->(ds);',
         { buildingName: values.buildingName, designStrategy });
      }
      await tx.commit();
    } catch (error) {
      console.log('Error occurred:', error);
      await tx.rollback();
      throw error;
    } finally {
      session.close();
    }
   navigate('/completion');
  };

  const handleFormSubmitDelete = async (values) => {
    console.log("formsubmitted: ",  values.Building);
    const session = driver.session();
    const tx = session.beginTransaction();
    //Create Ecosystem Service
    try {   
      await tx.run('MATCH (n:Building {name: $Building}) DETACH DELETE n', { Building: values.Building});

      await tx.commit();
    } catch (error) {
      await tx.rollback();
      throw error;
    } finally {
      session.close();
    }
    navigate('/completion');
  };

  return (
    <Box m="20px" sx={{ display: "grid", gridTemplateColumns: "repeat(3, minmax(0, 1fr))" }}>
    <Box m="20px" sx={{gridColumn: "span 2", padding: "15px", border: "solid grey 5px", borderTop: "solid #4cceac 5px", borderLeft: "solid #4cceac 5px"}}>
      <Header title="CREATE BUILDING" subtitle="Add Building" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(6, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            ><Box sx={{ gridColumn: "span 2" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Building Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.buildingName}
                name="buildingName"
                error={!!touched.buildingName && !!errors.buildingName}
              />
              <FormHelperText error>{touched.buildingName && errors.buildingName}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Link to a website, article or book that describes the building"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.link1}
                name="link1"
                error={!!touched.link1 && !!errors.link1}
              />
              <FormHelperText error>{touched.link1 && errors.link1}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Link to a photo of the building"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.linkphoto}
                name="linkphoto"
                error={!!touched.linkphoto && !!errors.linkphoto}
              />
              <FormHelperText error>{touched.linkphoto && errors.linkphoto}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Country"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.country}
                name="country"
                error={!!touched.country && !!errors.country}
              />
              <FormHelperText error>{touched.country && errors.country}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name="city"
                error={!!touched.city && !!errors.city}
              />
              <FormHelperText error>{touched.city && errors.city}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Street"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.street}
                name="street"
                error={!!touched.street && !!errors.street}
              />
              <FormHelperText error>{touched.street && errors.street}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Additional link to a website, article or book"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.link2}
                name="link2"
                error={!!touched.link2 && !!errors.link2}
              />
              <FormHelperText error>{touched.link2 && errors.link2}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 4" }}>
              <label style={{paddingLeft: '10px' }}>Select Design Strategies</label>
              <Field
                name="designStrategies"
                id="designStrategies"
                placeholder="Select Design Strategies"
                isMulti={true}
                component={MultiSelect}
                options={options}
                closeMenuOnSelect={false}
                error={!!touched.designStrategies && !!errors.designStrategies}
                helperText={touched.designStrategies && errors.designStrategies}
              />
              <FormHelperText error>{touched.designStrategies && errors.designStrategies}</FormHelperText>
              </Box>
            </Box>
            
            <Box display="flex" justifyContent="left" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create Building
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    <Box m="20px" sx={{ padding: "15px", border: "solid grey 5px", borderTop: "solid #f44336 5px", borderLeft: "solid #f44336 5px"}}>
     <Header title="DELETE BUILDING" subtitle="Remove Building"  color="#f44336" />

     <Formik
       onSubmit={handleFormSubmitDelete}
       initialValues={initialValuesDelete}
       validationSchema={checkoutSchemaDelete}
     >
       {({
         values,
         errors,
         touched,
         handleBlur,
         handleChange,
         handleSubmit,
       }) => (
         <form onSubmit={handleSubmit}>
           <Box
             display="grid"
             gap="30px"
             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
             sx={{
               "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
             }}
           >
             <Box sx={{ gridColumn: "span 3" }}>
             <label style={{ marginBottom: '10px', padding: '10px' }}>Select Building</label>
             <Field
               name="Building"
               id="Building"
               placeholder="Select Building"
               label="Building"
               isMulti={false}
               component={MultiSelect}
               options={optionsDelete}
               closeMenuOnSelect={false}
               error={!!touched.Building && !!errors.Building}
               helperText={touched.Building && errors.Building}
             />
             <FormHelperText error>{touched.Building && errors.Building}</FormHelperText>
             </Box>
           </Box>
           <Box display="flex" justifyContent="left" mt="20px">
             <Button type="submit" color="error" variant="contained">
               Delete Building
             </Button>
           </Box>
         </form>
       )}
     </Formik>
   </Box>
   </Box>
  );
};
//checkout schema 
const checkoutSchema = yup.object().shape({
  buildingName: yup.string().required("Required"),
  country: yup.string().required("Required"),
  city: yup.string().required("Required"),
  street: yup.string().required("Required"),
  link1: yup.string().required("Required"),
  designStrategies: yup.array().min(1, "Select atleast 1 Design Strategy").of(yup.string()).required("Select atleast 1 Design Strategy")
});
//initial values for form 
const initialValues = {
  buildingName: "",
  country:"",
  city:"",
  street:"",
  link1:"",
  link2:"",
  linkphoto:"",
  designStrategies:"",
};

//checkout schema DELETE
const checkoutSchemaDelete = yup.object().shape({
  Building: yup.string().required("Required"),
});
//initial values for form DELETE
const initialValuesDelete = {
  Building: "",
};

export default FormBuilding;
