export function getColor(feature) {
        const climate = feature.properties.climate;
        switch (climate) {
          case "Af Tropical-Rainforest":
            return "#0000ff";
          case "Am Tropical-Monsoon":
            return "#0078ff";
          case "Aw Tropical-Savanna":
            return "#45a9f9";
          case "BSh Arid-Steppe-Hot":
            return "#f5a500";
          case "BSk Arid-Steppe-Cold":
            return "#ffdc64";
          case "BWh Arid-Desert-Hot":
            return "#ff1010";       
          case "BWk Arid-Desert-Cold":
            return "#ff9696";
          case "Cfa Temperate-Withouth_dry_season-Hot_Summer":
            return "#c8ff50";
          case "Cfb Temperate-Withouth_dry_season-Warm_Summer":
            return "#69ff39";
          case "Cfc Temperate-Withouth_dry_season-Cold_Summer":
            return "#32c800";
          case "Csa Temperate-Dry_Summer-Hot_Summer":
            return "#ffff19";
          case "Csb Temperate-Dry_Summer-Warm_Summer":
            return "#c7c700";
          case "Cwa Temperate-Dry_Winter-Hot_Summer":
            return "#96ff96";
          case "Cwb Temperate-Dry_Winter-Warm_Summer":
            return "#64c864";
          case "Dfa Cold-Withouth_dry_season-Hot_Summer":
            return "#37c8ff";
          case "Dfa Cold-Withouth_dry_season-Very_Cold_Winter":
            return "#00465f";
          case "Dfb Cold-Withouth_dry_season-Warm_Summer":
            return "#37c8ff";
          case "Dfc Cold-Withouth_dry_season-Cold_Summer":
            return "#007d7d";
          case "Dsa Cold-Dry_Summer-Hot_Summer":
            return "#ff00ff";
          case "Dsb Cold-Dry_Summer-Warm_Summer":
            return "#c800c8";
          case "Dsc Cold-Dry_Summer-Cold_Summer":
            return "#963296";
          case "Dsd Cold-Dry_Summer-Very_Cold_Winter":
            return "#966496";  
          case "Dwa Cold-Dry_Winter-Hot_Summer":
            return "#aaafff";
          case "Dwb Cold-Dry_Winter-Warm_Summer":
            return "#5a78dc";
          case "Dwc Cold-Dry_Winter-Cold_Summer":
            return "#4b50b4";
          case "Dwd Cold-Dry_Winter-Very_Cold_Winter":
            return "#320087";    
          case "EF Polar-Frost":
            return "#656565";
          case "ET Polar-Tundra":
            return "#b3b3b3";     
          default:
            return "#FFFFFF";
        }}