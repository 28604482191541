import React, { useState } from 'react';
import { NetworkDataContext } from './NetworkDataContext';

export const NetworkDataProvider = ({ children }) => {
  const [networkdata, setnetworkData] = useState({ nodes: [], edges: [] }); //tracks current network nodes/edges

  return (
    <NetworkDataContext.Provider value={{ networkdata, setnetworkData }}>
      {children}
    </NetworkDataContext.Provider>
  );
};
