import React, { createContext, useEffect, useState } from 'react';
import neo4j from 'neo4j-driver';
import config from '../config.jsx';
export const Neo4jContext = createContext(null);

function Neo4jDriver({ children }) {
  const [driver, setDriver] = useState(null);
  const user = config.neo4j_user
  const uri = config.neo4j_uri
  const password = config.neo4j_password
  useEffect(() => {
    const newDriver = neo4j.driver(uri, neo4j.auth.basic(user, password), { disableLosslessIntegers: true }, (error) => {
      console.error("Error connecting to Neo4j: ", error);
      // You could display an error message here or take other actions as appropriate.
    });
    setDriver(newDriver);

  }, [uri, user, password]);

  return (
    <Neo4jContext.Provider value={driver}>
      {driver ? children : <div>Loading...</div>}
    </Neo4jContext.Provider>
  );
}

export default Neo4jDriver;