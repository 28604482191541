import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useContext, useState, useEffect } from 'react';
import { Neo4jContext } from '../../components/neo4jDriver';

const DataBuilding = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const driver = useContext(Neo4jContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchBuildings = async () => {
      const session = driver.session();
      const result = await session.run(
        "MATCH (b:Building) RETURN b.name AS name, b.country AS country, b.city AS city, b.street AS street, b.link AS link, b.additional_link AS additional_link, b.photo AS photo"
      );
      const formattedData = result.records.map((record, index) => ({
        id: index,
        name: record.get("name"),
        country: record.get("country"),
        city: record.get("city"),
        street: record.get("street"),
        link: record.get("link"),
        additional_link: record.get("additional_link"),
        photo: record.get("photo"),
      }));
      setRows(formattedData);
      session.close();
    };
    fetchBuildings();
  }, [driver]);
  console.log(rows);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "country", headerName: "Country", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "street", headerName: "Street", flex: 1 },
    { field: "link", headerName: "Link", flex: 1 },
    { field: "additional_link", headerName: "Additional Link", flex: 1 },
    { field: "photo", headerName: "Photo", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header
        title="DATA BUILDING"
        subtitle="List of all Buildings"
      />
      <Box
        m="0 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default DataBuilding;
