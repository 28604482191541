import { Box, Button, TextField, FormHelperText, useTheme} from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import MultiSelect from '../../components/CustomSelect.jsx';
import { useContext } from 'react';
import { Neo4jContext } from '../../components/neo4jDriver';
import GetEcosystems from '../../components/getEcosystems.jsx';
import { useNavigate } from 'react-router-dom';
import GetStrategies from '../../components/getStrategies.jsx';

const FormDesignStrategy = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const driver = useContext(Neo4jContext);
  const options = GetEcosystems();
  const navigate = useNavigate();
  const optionsdelete = GetStrategies();
  const handleFormSubmit = async (values) => {
    const session = driver.session();
    const tx = session.beginTransaction();
    //Create Design Strategy
    try {   
      await tx.run('CREATE (:Design_Strategies {Strategy: $designStrategy})', { designStrategy: values.designStrategy });
      console.log('CREATE (:Design_Strategies {Strategy: $designStrategy})', { designStrategy: values.designStrategy })
    //Make Relationship
      for (const ecosystemService of values.ecosystemServices) {
        await tx.run('MATCH(ds:Design_Strategies), (es:Ecosystem_Service) WHERE ds.Strategy = $designStrategy AND es.categories = $ecosystemService CREATE (ds)-[:Generate]->(es);',
         { designStrategy: values.designStrategy, ecosystemService });
      }

      await tx.commit();
    } catch (error) {
      await tx.rollback();
      throw error;
    } finally {
      session.close();
    }
    navigate('/completion');
  };

  const handleFormSubmitDelete = async (values) => {
    console.log(values.DesignStrategy);
    const session = driver.session();
    const tx = session.beginTransaction();
    console.log("submit")
    //Delete Design Strategy
    try {   
      await tx.run('MATCH (n:Design_Strategies {Strategy: $DesignStrategy}) DETACH DELETE n', { DesignStrategy: values.DesignStrategy });
      await tx.commit();
    } catch (error) {
      await tx.rollback();
      throw error;
    } finally {
      session.close();
    }
    navigate('/completion');
  };


  return (
    <Box m="20px" sx={{ display: "grid", gridTemplateColumns: "repeat(3, minmax(0, 1fr))" }}>
    <Box m="20px" sx={{ gridColumn: "span 2", padding: "15px", border: "solid grey 5px", borderTop: "solid #4cceac 5px", borderLeft: "solid #4cceac 5px"}}>
      {console.log(options)} {/* Add console.log here */}
      <Header title="CREATE DESIGN STRATEGY" subtitle="Add Design Strategy"/>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            ><Box sx={{ gridColumn: "span 3" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name of Design Strategy"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.designStrategy}
                name="designStrategy"
                error={!!touched.designStrategy && !!errors.designStrategy}
              />
              <FormHelperText error>{touched.designStrategy && errors.designStrategy}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 3" }}>
              <label style={{ marginBottom: '10px', padding: '10px' }}>Select Ecosystem Services</label>
              <Field
                name="ecosystemServices"
                id="ecosystemServices"
                placeholder="Select Ecosystem Services"
                label="Ecosystem Services"
                isMulti={true}
                component={MultiSelect}
                options={options}
                closeMenuOnSelect={false}
                error={!!touched.ecosystemServices && !!errors.ecosystemServices}
                helperText={touched.ecosystemServices && errors.ecosystemServices}
              />
              <FormHelperText error>{touched.ecosystemServices && errors.ecosystemServices}</FormHelperText>
              </Box>
            </Box>
            <Box display="flex" justifyContent="left" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create Design Strategy
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
    <Box m="20px" sx={{ padding: "15px", border: "solid grey 5px", borderTop: "solid #f44336 5px", borderLeft: "solid #f44336 5px"}}>
    {console.log(options)} {/* Add console.log here */}
    <Header title="DELETE DESIGN STRATEGY"  subtitle="Remove Design Strategy" color="#f44336"/>

    <Formik
      onSubmit={handleFormSubmitDelete}
      initialValues={initialValuesDelete}
      validationSchema={checkoutSchemaDelete}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <Box sx={{ gridColumn: "span 3"}}>
            <label style={{ marginBottom: '10px', padding: '10px' }}>Select Design Strategy</label>
            <Field
              name="DesignStrategy"
              id="DesignStrategy"
              placeholder="Select Design Strategy"
              label="Design Strategy"
              isMulti={false}
              component={MultiSelect}
              options={optionsdelete}
              closeMenuOnSelect={false}
              error={!!touched.DesignStrategy && !!errors.DesignStrategy}
              helperText={touched.DesignStrategy && errors.DesignStrategy}
            />
            <FormHelperText error>{touched.DesignStrategy && errors.DesignStrategy}</FormHelperText>
            </Box>
          </Box>
          <Box display="flex" justifyContent="left" mt="20px">
            <Button type="submit" color="error" variant="contained">
              Delete Design Strategy
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  </Box>
  </Box>
  );
};
//Add 
const checkoutSchema = yup.object().shape({
  designStrategy: yup.string().required("Required"),
  ecosystemServices: yup.array().min(1, "Select atleast 1 Ecosystem Service").of(yup.string()).required("Select atleast 1 Ecosystem Service")
});
const initialValues = {
  designStrategy: "",
  ecosystemServices:"",
};
//delete 
const checkoutSchemaDelete = yup.object().shape({
  DesignStrategy: yup.string().required("Select 1 Design Strategy")
});
const initialValuesDelete = {
  DesignStrategy: "",
};

export default FormDesignStrategy;
