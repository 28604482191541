import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, LayersControl, GeoJSON } from 'react-leaflet';
import { tokens } from '../../theme';
import { Box, useTheme } from '@mui/material';
import Header from '../../components/Header';
import { Icon } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import geojson from '../../data/images/climateData.json';
import Legend from "./legendaClimate";
import { getColor } from "./climatecolors";
import BuildingData from "../../components/getBuildingData";

const center = [42.6384261, 12.674297];//LatLong of Italy
const Mapview = ({ buildingdata: propBuildingData, mapWidth: propmapwidth, mapHeight: propmapHeight }) => {
  const buildingdata = propBuildingData || BuildingData();
  const mapWidth = propmapwidth || "100%";
  const mapHeight = propmapHeight || "83vh";

  console.log("MAPVIEW BUILDINGDATA", buildingdata)
  const markers = buildingdata.map((node) => {
    const lat = node.properties && node.properties.length > 0 ? node.properties.find(prop => prop.location)?.location.x : null;
    const lon = node.properties && node.properties.length > 0 ? node.properties.find(prop => prop.location)?.location.y : null;
    if (lat && lon) {
      const popUpContent = (
        <div>
          <h3>{node.label.replace(/_/g, ' ')}</h3>
          {Object.entries(node.properties).map(([key, value]) => (
            typeof value === 'object' ? (
              Object.entries(value).map(([nestedKey, nestedValue]) => (
                typeof nestedValue !== 'object' ? (
                  <div key={node.id}>
                    <b>{nestedKey}: </b>
                    {nestedValue.startsWith('http') ? (
                      <a href={nestedValue} target="_blank" rel="noopener noreferrer">Link</a>
                    ) : (
                      nestedValue
                    )}
                  </div>
                ) : null
              ))
            ) : (
              null
            )
          ))}
        </div>
      );
      
      
      
  
      return {
        geocode: [lon, lat],
        key: node.id,
        popUp: popUpContent,
      };
    }
  
    return null;
  }).filter(marker => marker !== null); // filter out null values
    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const bordercolor = colors.primary[400];
  const customIcon = new Icon({ //add custom marker
    iconUrl: "https://cdn-icons-png.flaticon.com/512/3177/3177361.png",
    iconSize: [36, 36]
  });
  
  function style(feature) {
    return {
      color: getColor(feature),
      attribution:'&copy; <a href="https://ggis.un-igrac.org/layers/igrac:other_climate_2007_koppen_geiger">Koppen-Geiger</a>'
    };
  }
  
  const [currentLayer, setCurrentLayer] = useState('OpenStreetMap');

  const layers = [ //The different layers
    {
      name: 'OpenStreetMap',
      layer: <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    },
  ];

  const handleLayerChange = (e) => {
    setCurrentLayer(e.name);
  };

  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleOverlayChange = (e) => {
    setActiveOverlay(e.name === activeOverlay ? null : e.name);
    if (e.name === 'Climate zones') {
      setActiveOverlay('Climate zones');
    }
  };

  return (
    <Box m="20px">
      <Header title="MAPVIEW" />
      <MapContainer
        center={center}
        zoom={2}
        style={{ borderColor: bordercolor, borderStyle: "solid", borderWidth: "5px", width: mapWidth, height: mapHeight }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LayersControl position="topright" onChange={handleLayerChange}>
          {layers.map((layer) => (
            <LayersControl.BaseLayer
              key={layer.name}
              name={layer.name}
              checked={currentLayer === layer.name}
            >
              {layer.layer}
            </LayersControl.BaseLayer>
          ))}
          <LayersControl.Overlay 
              name="Climate zones" 
              checked={activeOverlay === 'Climate zones'} 
              onChange={handleOverlayChange}>
            <GeoJSON data={geojson} style={style}/>
          </LayersControl.Overlay>
        </LayersControl>
  
        {activeOverlay === "Climate zones" && <Legend activeOverlay={activeOverlay} />}
  
        <MarkerClusterGroup chunkedLoading>
        {markers.map((marker) => (
            marker && marker.geocode ? (
              <Marker position={marker.geocode} icon={customIcon} key={marker.key}>
                <Popup >
                  <div style={{ wordBreak: "break-word" }}>
                    <h4>{marker.popUp}</h4>
                  </div>
                </Popup>
              </Marker>
            ) : null
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </Box>
  );
}
  export default Mapview;