import { Box, Button, TextField, FormHelperText} from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import MultiSelect from '../../components/CustomSelect.jsx';
import GetProperties from '../../components/getProperties.jsx';

const QueryForm = ({ onSubmit }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const options = [  
    {    label: 'Node',
            options: [  { value: 'b:Building', label: 'Building', group: "Node" },
                        { value: 'ds:Design_Strategies', label: 'Design Strategies', group: "Node" },
                        { value: 'es:Ecosystem_Service', label: 'Ecosystem Service', group: "Node" },
                    ]},
    {   label: 'Relationship',
            options: [  { value: 'i:Implemented', label: 'Implemented', group: "Relationship" },
                        { value: 'g:Generated', label: 'Generated', group: "Relationship" },
]}
];


const properties = GetProperties();

  return (
    <Box m="20px" style={{ maxHeight: "15vh"}}>
      <Formik
        onSubmit={(values) => {
          onSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              id="queryform"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(5, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
              }}
            >
              <Box sx={{ gridColumn: "span 1" }}>
              <label style={{ marginBottom: '10px', padding: '10px' }}>Select Node</label>
              <Field
                name="select1"
                id="select1"
                placeholder=""
                label="select1"
                component={MultiSelect}
                options={options}
                closeMenuOnSelect={false}
                error={!!touched.select1 && !!errors.select1}
                helperText={touched.select1 && errors.select1}
              />
              <FormHelperText error>{touched.select1 && errors.select1}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 1" }}>
              <label style={{ marginBottom: '10px', padding: '10px' }}>Select Property</label>
              <Field
                name="property"
                id="property"
                placeholder=""
                label="property"
                component={MultiSelect}
                options={properties}
                closeMenuOnSelect={false}
                error={!!touched.property && !!errors.property}
                helperText={touched.property && errors.property}
              />
              <FormHelperText error>{touched.property && errors.property}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 1", maxHeight: "10px" }}>
              <label style={{ marginBottom: '10px', padding: '10px' }}>Property Value</label>
              <TextField 
                fullWidth
                name="propertyValue"
                id="propertyValue"
                variant="filled"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                // value={values.propertyValue}
                error={!!touched.propertyValue && !!errors.propertyValue}
                inputProps={{
                  style: { margin: "0px", height: "10px" } // set the height of the input element to 30px
                }}
              />
              <FormHelperText error>{touched.propertyValue && errors.propertyValue}</FormHelperText>
              
              </Box>
              <Box justifyContent="center" mt="20px" sx={{ gridColumn: "span 1"}}>
              <Button name="newVisualization" type="submit" color="info" variant="contained" onClick={() => onSubmit(values, "New")}>
                New Visualization
              </Button>
              </Box>
              <Box justifyContent="center" mt="20px" sx={{ gridColumn: "span 1"}}>
              <Button name= "extendVisualization"type="submit" color="secondary" variant="contained" onClick={() => onSubmit(values, "Extend")}>
                Extend Visualization
              </Button>
              </Box>
              {/* <Box justifyContent="center" mt="20px" sx={{ gridColumn: "span 1"}}>
              <Button name="nodeVisualization" type="submit" color="warning" variant="contained" onClick={() => onSubmit(values, "Node")}>
                Extend Node
              </Button>
            </Box> */}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  select1: yup.string().required("Required"),
  propertyValue: yup.string().when("property", {
    is: (value) => value === "default", // check if property is not the default value
    then: yup.string().required("Required"), // make propertyValue field required
    otherwise: yup.string() // leave propertyValue field optional
  }),
});


const initialValues = {
  propertyValue: "",
  select1:"",
  property: "",
  propertyOptions: "",
};

export default QueryForm;
