import { useEffect, useState, useContext } from 'react';
import { Neo4jContext } from './neo4jDriver';

function GetProperties() {
  const [data, setData] = useState([]);
  const driver = useContext(Neo4jContext);
  const options = [{ value: "", label: "" }, ...data.map((item) => ({ value: item, label: item }))];
  useEffect(() => {
    const session = driver.session();
    session.run('MATCH (es:Ecosystem_Service) RETURN keys(es) as properties LIMIT 1 UNION MATCH (b:Building) RETURN keys(b) as properties LIMIT 1 UNION MATCH (ds:Design_Strategies) RETURN keys(ds) as properties LIMIT 1')
      .then((result) => {
        const data = result.records.flatMap((record) => record.get('properties'));
        setData(Array.from(new Set(data)));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        session.close();
      });
  }, [driver]);

  return options;
}

export default GetProperties;
