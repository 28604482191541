//Used to add locaton points as property to all building nodes in the Neo4j dataset (TO RUN UNCOMMENT line 12 in ../../scenes/information/index.jsx)
import { useContext } from 'react';
import { Neo4jContext } from './neo4jDriver';
import BuildingData  from "./getBuildingData";
import axios from 'axios';


function AddPoints() {
    const buildingdata = BuildingData();
    console.log(BuildingData())
    const driver = useContext(Neo4jContext);
    const session = driver.session();
    async function getAddressCoordinates(buildingdata) {
        for (const [index, item] of buildingdata.entries()) {
          const nodeId = item.id;
          console.log("nodeID",nodeId)
          const address = item.address;
          try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`);
            if (response.data.length) {
              const { lat, lon } = response.data[0];
              console.log("Log every item in BuildingData", nodeId, address, { lat, lon })
              console.log(lat)
              console.log(lon)
              session.run(`MATCH (n:Building) WHERE id(n) = ${nodeId} SET n.location = point({ latitude: ${lat}, longitude: ${lon} }) RETURN n`)
                .then(result => {
                  console.log("ADDED TO NEO4j")
                })
                .catch(error => {
                  console.error(`Error executing Neo4j query for node ${nodeId}`, error);
                });
            } else {
              console.log('Could not find address');
            }
          } catch (error) {
            console.error(`Error fetching coordinates for address ${address}`, error);
          }
          await new Promise(resolve => setTimeout(resolve, 3000)); // add 1 second delay between each API call
        }
        session.close()
      }
      

getAddressCoordinates(buildingdata);

}

export default AddPoints