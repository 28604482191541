import React, { useContext, useState, useEffect } from 'react';
import { NetworkDataContext } from './NetworkDataContext';
import { Neo4jContext } from './neo4jDriver';
  function InteractiveBuildingData() {
    const driver = useContext(Neo4jContext);
    const [MarkerInfo, setMarkerInfo] = useState([]);
    const [filteredBuildingData, setFilteredBuildingData] = useState([]);
    const { networkdata } = useContext(NetworkDataContext);

    useEffect(() => {
        const session = driver.session();
        session
        .run(`MATCH (b:Building) RETURN b`)
        .then((result) => {
            const markerData = result.records.map((record) => {
            const node = record.get('b');
            const nodeId = node.identity.toString();  
            const properties = Object.entries(node.properties).map(([key, value]) => {
                return { [key]: value };
                }
            );
                return {
                    id: nodeId,
                    label: node.labels[0].replace(/_/g, ' '),
                    properties: properties,
                };
            });
                setMarkerInfo(markerData);
        })
        .catch((error) => console.error(error))
        .finally(() => {
            session.close();
        });
    }, [networkdata]);
    useEffect(() => {
    let addresses = [];
    // Make address from properties Country/City/Street
    for (let i = 0; i < MarkerInfo.length; i++) { //Loop over data and add address to it
        const item = MarkerInfo[i];
        const country = item.properties && item.properties.find(prop => prop.country)?.country || '';
        const city = item.properties && item.properties.find(prop => prop.city)?.city || '';
        const street = item.properties && item.properties.find(prop => prop.street)?.street || '';
        let fullAddress = [country, city, street].filter(Boolean).join(', '); // filter out empty strings and join with ', '
        fullAddress = fullAddress.charAt(0).toUpperCase() + fullAddress.slice(1); // capitalize the first letter of the address
        addresses.push(`Address of item ${i}: ${fullAddress}`)
        MarkerInfo[i] = { ...item, address: fullAddress };
      }
      //check that networkdata is not empty, so that (.map is not a function) error does not apply.
      if (networkdata.nodes.length > 0) {
        const networkIds = networkdata.nodes.map((node) => (node.id));
        const filteredData = MarkerInfo.filter((building) => {
          return networkIds.includes(building.id);
        });
        setFilteredBuildingData(filteredData);
      };
    }, [MarkerInfo]);
      return filteredBuildingData;     
} 

export default InteractiveBuildingData;
