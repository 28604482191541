import { Box, Button} from "@mui/material";
import Header from "../../components/Header";
import React from 'react';
import { useNavigate } from 'react-router-dom';
function StrategyCompletion() {
    const navigate = useNavigate();
    function handleButtonClick() {
        navigate(-1);
      }
    function goToVisualizations() {
        navigate('/mapview');
      }
return (
    <Box m="20px" background="">
            <Header
        title="FORM SUBMITTED"
      />
    <Box>
        <Button onClick={handleButtonClick} type="submit" color="secondary" variant="contained">
            File other form
        </Button>
        <Button onClick={goToVisualizations} type="submit" color="secondary" variant="contained" style={{marginLeft: '15px' }}>
            Go to Visualizations
        </Button>    
    </Box>
    </Box>
    );
    }
    
    export default StrategyCompletion;