import { useTheme } from "@mui/material";

export function useGraphOptions() {
    const theme = useTheme();

  const options = {
    interaction: {
      hover: true,
      navigationButtons: true,
      selectable: true,
      selectConnectedEdges: true,
      hoverConnectedEdges: true,
      tooltipDelay: 300,
      zoomView: true,
      zoomSpeed: 1,
      dragNodes: true,
      dragView: true,
      hideEdgesOnDrag: false,
      hideEdgesOnZoom: false,
      hideNodesOnDrag: false,
      keyboard: {
        enabled: false,
        bindToWindow: true
      },
    },
    nodes: {
      shape: 'dot',
      font: {
        color: theme.palette.text.primary
      },
    },
    edges: { 
      color: theme.palette.text.primary,
      hidden: false,
      arrows: { to: true },
      width: 1,
      length: 250,
      font: {
        color: theme.palette.text.primary,
        size: 10,
        align: 'middle',
        strokeWidth: 10,
        strokeColor: theme.palette.background.default
      },
    },
    physics: {
      barnesHut: {
        centralGravity: 0.001, // nodes do not come back together super close after seperating them by dragging
        // springLength: 250,
        // springConstant: 0.05,
        // damping: 0.5,
      },
      // hierarchicalRepulsion: {
      //   centralGravity: 0.0,
      //   springLength: 200,
      //   springConstant: 0.01,
      //   nodeDistance: 200,
      //   damping: 0.09
      // }
    },
  };

  return options;
};

