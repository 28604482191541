import { Box, Button, TextField, FormHelperText} from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import MultiSelect from '../../components/CustomSelect.jsx';
import { useContext } from 'react';
import { Neo4jContext } from '../../components/neo4jDriver';
import { useNavigate } from 'react-router-dom';
import GetEcosystems from '../../components/getEcosystems.jsx';


const FormEcoSystemService = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const driver = useContext(Neo4jContext);
  const optionsDelete = GetEcosystems();
  const options = [
    { value: 'Provisioning Services', label: 'Provisioning Services' },
    { value: 'Regulating Services', label: 'Regulating Services' },
    { value: 'Cultural Services', label: 'Cultural Services'}
  ]
  const navigate = useNavigate();

  // form handler
  const handleFormSubmit = async (values) => {
    console.log("formsubmitted: ",  values.ecosystemService);
    const session = driver.session();
    const tx = session.beginTransaction();
    //Create Ecosystem Service
    try {   
      await tx.run('CREATE (:Ecosystem_Service {categories: $ecosystemService, group: $group})', { ecosystemService: values.ecosystemService, group: values.group });

      await tx.commit();
    } catch (error) {
      await tx.rollback();
      throw error;
    } finally {
      session.close();
    }
    navigate('/completion');
  };

  // Delete form handler
    const handleFormSubmitDelete = async (values) => {
      console.log("formsubmitted: ",  values.EcosystemService);
      const session = driver.session();
      const tx = session.beginTransaction();
      //Create Ecosystem Service
      try {   
        await tx.run('MATCH (n:Ecosystem_Service {categories: $EcosystemService}) DETACH DELETE n', { EcosystemService: values.EcosystemService});
  
        await tx.commit();
      } catch (error) {
        await tx.rollback();
        throw error;
      } finally {
        session.close();
      }
      navigate('/completion');
    };


  return (
    <Box m="20px" sx={{ display: "grid", gridTemplateColumns: "repeat(3, minmax(0, 1fr))" }}>
    <Box m="20px" sx={{ gridColumn: "span 2", padding: "15px", border: "solid grey 5px", borderTop: "solid #4cceac 5px", borderLeft: "solid #4cceac 5px"}}>
      <Header title="CREATE ECOSYSTEM SERVICE" subtitle="Add Ecosystem Service" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            ><Box sx={{ gridColumn: "span 3" }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name of Ecosystem Service"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ecosystemService}
                name="ecosystemService"
                error={!!touched.ecosystemService && !!errors.ecosystemService}
              />
              <FormHelperText error>{touched.ecosystemService && errors.ecosystemService}</FormHelperText>
              </Box>
              <Box sx={{ gridColumn: "span 3" }}>
              <label style={{ marginBottom: '10px', padding: '10px' }}>Select Group</label>
              <Field
                name="group"
                id="group"
                placeholder="Select Group"
                label="Group"
                isMulti={false}
                component={MultiSelect}
                options={options}
                closeMenuOnSelect={false}
                error={!!touched.group && !!errors.group}
                helperText={touched.group && errors.group}
              />
              <FormHelperText error>{touched.group && errors.group}</FormHelperText>
              </Box>
            </Box>
            <Box display="flex" justifyContent="left" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create Ecosystem Service
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
     <Box m="20px" sx={{ padding: "15px", border: "solid grey 5px", borderTop: "solid #f44336 5px", borderLeft: "solid #f44336 5px"}}>
     <Header title="DELETE ECOSYSTEM SERVICE" subtitle="Remove Ecosystem Service"  color="#f44336" />

     <Formik
       onSubmit={handleFormSubmitDelete}
       initialValues={initialValuesDelete}
       validationSchema={checkoutSchemaDelete}
     >
       {({
         values,
         errors,
         touched,
         handleBlur,
         handleChange,
         handleSubmit,
       }) => (
         <form onSubmit={handleSubmit}>
           <Box
             display="grid"
             gap="30px"
             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
             sx={{
               "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
             }}
           >
             <Box sx={{ gridColumn: "span 3" }}>
             <label style={{ marginBottom: '10px', padding: '10px' }}>Select Ecosystem Service</label>
             <Field
               name="EcosystemService"
               id="EcosystemService"
               placeholder="Select Ecosystem Service"
               label="EcosystemService"
               isMulti={false}
               component={MultiSelect}
               options={optionsDelete}
               closeMenuOnSelect={false}
               error={!!touched.EcosystemService && !!errors.EcosystemService}
               helperText={touched.EcosystemService && errors.EcosystemService}
             />
             <FormHelperText error>{touched.EcosystemService && errors.EcosystemService}</FormHelperText>
             </Box>
           </Box>
           <Box display="flex" justifyContent="left" mt="20px">
             <Button type="submit" color="error" variant="contained">
               Delete Ecosystem Service
             </Button>
           </Box>
         </form>
       )}
     </Formik>
   </Box>
   </Box>
  );
};
//checkout schema
const checkoutSchema = yup.object().shape({
  ecosystemService: yup.string().required("Required"),
  group: yup.string().required("Select 1 group")
});
//initial values for form
const initialValues = {
  ecosystemService: "",
  group:"",
};

//checkout schema DELETE
const checkoutSchemaDelete = yup.object().shape({
  EcosystemService: yup.string().required("Required"),
});
//initial values for form DELETE
const initialValuesDelete = {
  EcosystemService: "",
};

export default FormEcoSystemService;
