import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import AddPoints from '../../components/AddPointstoNodes'

const Information = () => {
  //  AddPoints(); //uncomment to run once... (Adds location point to each building node)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const linkcolor = colors.greenAccent[500];
  return (
    <Box m="20px">
      <Header title="TOOL INFORMATION" subtitle="Learn more about the tool" />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What is the purpose of this tool?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The purpose of the tool is to inform building developers about the possibilities of implementing sustainable building design strategies into their future building projects.
          For a full description of the project checkout this <a href="https://git.science.uu.nl/vig/bscprojects/sustainability-graph-data-exploration/-/blob/main/Bsc_Thesis_Charles_Klijnman_5995272.pdf">document</a>. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How should this tool be used?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This tool can be used to explore and extend the dataset on Sustainable Building Design. For a detailed overview of all the functionalities see the <a href="https://git.science.uu.nl/vig/bscprojects/sustainability-graph-data-exploration/-/blob/main/User_Manual.pdf">user manual</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Who are the collaborators of this tool?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This tool has been developed by <a style={{ color: linkcolor  }} href="https://www.linkedin.com/in/charles-klijnman-812b45228?trk=public_profile_browsemap&originalSubdomain=nl">Charles Klijnman</a> in collaboration with <a style={{ color: linkcolor  }} href="https://www.linkedin.com/in/katharinahecht?originalSubdomain=nl">Katharina Hecht</a>. Supervised by <a style={{ color: linkcolor  }} href="https://mbehrisch.github.io/">Michael Behrisch</a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Information;
