import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useContext, useState, useEffect } from 'react';
import { Neo4jContext } from '../../components/neo4jDriver';

const DataEcosystem = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const driver = useContext(Neo4jContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchBuildings = async () => {
      const session = driver.session();
      const result = await session.run(
        "MATCH (es:Ecosystem_Service) RETURN es.categories as categories, es.group as group"
      );
      const formattedData = result.records.map((record, index) => ({
        id: index,
        categories: record.get("categories"),
        group: record.get("group"),
      }));
      setRows(formattedData);
      session.close();
    };
    fetchBuildings();
  }, [driver]);
  console.log(rows);

  const columns = [
    { field: "categories", headerName: "Name", flex: 1 },
    { field: "group", headerName: "Provisioning Group", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header title="DATA ECOSYSTEM SERVICES" subtitle="All ecosystem services" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid rows={rows} columns={columns} components={{ Toolbar: GridToolbar }}/>
      </Box>
    </Box>
  );
};

export default DataEcosystem;
