// Ecosystems.jsx

import { useEffect, useState, useContext } from 'react';
import { Neo4jContext } from './neo4jDriver';

function GetStrategies() {
  const [data, setData] = useState([]);
  const driver = useContext(Neo4jContext);

  useEffect(() => {
    const session = driver.session();
    session.run('MATCH (ds:Design_Strategies) RETURN ds')
      .then((result) => {
        const data = result.records.map((record) => record.get('ds').properties);
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        session.close();
      });
  }, [driver]);
  const options = data.map((item) => ({ value: item.Strategy, label: item.Strategy }));
  return options;
}

export default GetStrategies;
