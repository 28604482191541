import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Information from "./scenes/information";
import DataEcosystem from "./scenes/dataEcosystem";
import DataStrategy from "./scenes/dataStrategy";
import DataBuilding from "./scenes/dataBuilding";
import Graphview from "./scenes/graphview"; 
import Multiview from "./scenes/multi-view"; 
import Mapview from "./scenes/mapview";
import FormDesignStrategy from "./scenes/formDesignStrategy";
import FormBuilding from "./scenes/formBuilding";
import FormEcosystemService from "./scenes/formEcosystemService";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Neo4jDriver from './components/neo4jDriver';
import StrategyCompletion from './scenes/formDesignStrategy/completion'
import PasswordProtect from './PasswordProtect';
import { NetworkDataProvider } from './components/NetworkDataProvider'
import KeepAlive from "./components/KeepAlive";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        <PasswordProtect>
          <Sidebar isSidebar={isSidebar} />
          <NetworkDataProvider>
          <Neo4jDriver>
          <KeepAlive />
          <main className="content">
            <Routes>
              <Route path="/" element={<Information />} />
              <Route path="/multiview" element={<Multiview />} />
              <Route path="/graphview" element={<Graphview />} />
              <Route path="/formDesignStrategy" element={<FormDesignStrategy />} />
              <Route path="/formEcosystemService" element={<FormEcosystemService />} />
              <Route path="/formBuilding" element={<FormBuilding />} />
              <Route path="/Mapview" element={<Mapview/>} />
              <Route path="/dataEcosystem" element={<DataEcosystem />} />
              <Route path="/dataStrategy" element={<DataStrategy />} />
              <Route path="/dataBuilding" element={<DataBuilding />} />
              <Route path="/completion" element ={<StrategyCompletion/>}/>
            </Routes>
          </main>
          </Neo4jDriver>
          </NetworkDataProvider>
          </PasswordProtect>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
