import { useEffect, useState, useContext } from 'react';
import { Neo4jContext } from './neo4jDriver';

function GetEcosystems() {
  const [data, setData] = useState([]);
  const driver = useContext(Neo4jContext);

  useEffect(() => {
    const session = driver.session();
    session.run('MATCH (es:Ecosystem_Service) RETURN es')
      .then((result) => {
        const data = result.records.map((record) => record.get('es').properties);
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        session.close();
      });
  }, [driver]);
  const options = data.map((item) => ({ value: item.categories, label: item.categories }));
  return options;
}

export default GetEcosystems;
