import React from 'react';
import Graphview from "../graphview/index.jsx"; 
import Mapview from "../mapview/index.jsx";
import { Box } from '@mui/material';
import InteractiveBuildingData  from '../../components/getInteractiveMarkers';

const Multiview = () => {
  const markers = InteractiveBuildingData()

  return (
    <Box
      sx={{ display: "grid", gridTemplateColumns: "repeat(5, minmax(0, 1fr))" }}
    >
      <Box gridColumn="span 3" gridRow="1">
        <Graphview />
      </Box>
      <Box gridColumn="span 2" gridRow="1">
        <Mapview buildingdata={markers} mapWidth="100%" mapHeight="83vh"/>
      </Box>
    </Box>
  );
  
};

export default Multiview;


