import { useContext, useState} from "react";
import { ProSidebar, Menu, MenuItem} from "react-pro-sidebar"
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from "react-router-dom";
import { ColorModeContext, tokens } from '../../theme';
import InfoIcon from '@mui/icons-material/Info';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TimelineIcon from '@mui/icons-material/Timeline';
import MapIcon from '@mui/icons-material/Map';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import SpaIcon from '@mui/icons-material/Spa';

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  };
const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const colorMode = useContext(ColorModeContext);

    return( 
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important"
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important"
                },
                "& .pro-inner-item:hover":{
                    color: "#868dfb !important"
                },
                "& .pro-menu-item-ative":{
                    color: "#6870fa !important" 
                },
            }}
        >
        <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* MENU TITLE */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h5" color={colors.grey[100]}>
                Sustainable Buildings
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="0px">
              <Box textAlign="center">
                {/* <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Sustainable Buildings
                </Typography> */}
                {/* <Typography  color={colors.greenAccent[500]}>
                Design Strategies & Ecosystem services
                </Typography> */}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "10px 0 0px 20px" }}
            >
              Views
            </Typography>
            <Item
              title="Knowledge Graph"
              to="/graphview"
              icon={<TimelineIcon />}
              selected={selected}
              setSelected={setSelected}
            />
                        <Item
              title="Map"
              to="/mapview"
              icon={<MapIcon />}
              selected={selected}
              setSelected={setSelected}
            />
                        <Item
              title="Multi view"
              to="/multiview"
              icon={<ViewComfyIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "10px 0 0px 20px" }}
            >
              Forms
            </Typography>
            <Item
              title="Design Strategy"
              to="/formDesignStrategy"
              icon={<InsertDriveFileIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Ecosystem Service"
              to="/formEcosystemService"
              icon={<SpaIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Building"
              to="/formBuilding"
              icon={<DomainAddIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "10px 0 0 20px" }}
            >
              Data
            </Typography>
            <Item
              title="Ecosystem services"
              to="/dataEcosystem"
              icon={<EnergySavingsLeafIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Design strategies"
              to="/dataStrategy"
              icon={<DesignServicesIcon />}
              selected={selected}
              setSelected={setSelected}
            />
                        <Item
              title="Buildings"
              to="/dataBuilding"
              icon={<MapsHomeWorkIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "10px 0 0px 20px" }}
            >
              About
            </Typography>
            <Item
              title="Tool information"
              to="/"
              icon={<InfoIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <IconButton sx={{alignItems: 'flex-end', m: "15px 0 5px 20px" }} onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton> 

          </Box>
        </Menu>
      </ProSidebar>

        </Box>
    );
}

export default Sidebar;