import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Box, Button, TextField } from "@mui/material";
import Header from "./components/Header";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import config from './config';
function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [, setCookie] = useCookies(['authenticated']);
  const history = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleLogin = (event) => {
    event.preventDefault();

    // validate credentials
    if (username === config.username && password === config.password) {
      // set session cookie and redirect to home page
      setCookie('authenticated', true, { path: '/' });
      history('/');
    } else {
      // display error message
      setErrorMessage('Invalid username or password');
    }
  };

  return (

<Box sx={{
      
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
      }}>
        <Box>
<Header title="LOGIN" subtitle="Sustainable Building Design"/>
</Box>
<Formik
  onSubmit={handleLogin}
>
  {({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  }) => (
    <form onSubmit={handleLogin}>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
        }}
      ><Box ml=""sx={{ gridColumn: "span 1" }}>
        <TextField
          label = "Username" type="text" value={username} onChange={(e) => setUsername(e.target.value)}
          focused={username !== ''}
        InputLabelProps={{
          focused: username !== '',
          style: {
            color: username !== '' ? '#ffffff' : '#ffffff',
          }
        }}
        sx={{
          '& .MuiInput-root:focus': {
            borderColor: 'lightgrey',
          },
        }}
        />
        </Box>
        <Box ml="" sx={{ gridColumn: "span 1" }}>
        <TextField label= "Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                  focused={password !== ''}
                  InputLabelProps={{
                    focused: password !== '',
                    style: {
                      color: password !== '' ? '#ffffff' : '#ffffff'
                    }
                  }}
        />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt="20px">
        <Button type="submit" color="secondary" variant="contained">
          Login
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" mt="20px">
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </Box>
    </form>
  )}
</Formik>
</Box>
  );
}

function PasswordProtect({ children }) {
  const [cookies] = useCookies(['authenticated']);
  const history = useNavigate();

  if (!cookies.authenticated) {
    history('/login');
    return <LoginPage />;
  }

  return children;
}

export default PasswordProtect;
